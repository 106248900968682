import React, { Component } from 'react';
import './App.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


// const lamps = [
//   [51.15308, 16.94144486],
//   [51.152903, 16.941396],
//   [51.1527349, 16.941206],
//   [51.1527349, 16.941206],
//   [51.1556273, 16.9395065],
//   [51.1554272, 16.9410637],
//   [51.1553936, 16.9420667],
//   [51.1549417, 16.9421123],
//   [51.154796, 16.9420367],
//   [51.1545705, 16.9419174],
//   [51.1542591, 16.9417631],
// ]


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      lat: '',
      long: '',
      position: [51.1526515, 16.9410885],
    };
  };

  getLocation() {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.setState(
          {
            date: new Date(),
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
            position: [pos.coords.latitude, pos.coords.longitude]
          }
        )
      },
      err => {
        alert(err.code.toString() + ' ' + err.message.toString())
      },
      {
        enableHighAccuracy: true,
        // timeout: 10000, 
        maximumAge: 0
      }
    );
  };

  componentDidMount() {
    if (navigator.geolocation) {
      this.timerID = setInterval(
        () => this.tick(),
        1000
      );
      this.getLocation();
    } else {
      console.log('Lipa jakaś coś nie działa!');
    }
  };

  componentWillUnmount() {

  };


  tick() {
    this.getLocation();
  };




  render() {
    return (
      <div>
        <h2>Date: {this.state.date.toLocaleDateString()} time: {this.state.date.toLocaleTimeString()}</h2>
        <h3>Your position: {this.state.lat}, {this.state.long} </h3>
        <div>
          <MapContainer
            className="markercluster-map"
            center={this.state.position} zoom={20} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={this.state.position}>
              <Popup>
                Lat: {this.state.position[0]}, Lang: {this.state.position[1]}
              </Popup>
            </Marker>


            {/* {lamps.map(
              (itm) =>
                <Marker position={itm}>
                  <Popup>
                    Lat: {itm[0]}, Lang: {itm[1]}
                  </Popup>
                </Marker>
            )} */}

          </MapContainer>
        </div>
      </div>
    );
  };
}

export default App;
